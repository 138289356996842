<template>
    <v-card flat tile>
        <v-card-text>
            <p>Customer will be charged the amount set by the tier corresponding to the total quantity. Each tier can have a flat fee and/or a per-unit price. For the selected tier, subtotal is the flat fee plus the quantity sold times the unit price in that tier. You can set a maximum of units they can buy or leave the last tier without a maximum for unlimited overage.</p>

            <v-simple-table>
                <template #default>
                    <thead>
                        <tr>
                            <th>Tier</th>
                            <th>Max units</th>
                            <th>Flat price</th>
                            <th>Unit price</th>
                            <th style="text-align: end;">
                                <v-btn icon color="green darken-2" @click="addTier">
                                    <font-awesome-icon :icon="['fas', 'plus']" fixed-width></font-awesome-icon>
                                </v-btn>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <EditableProductPriceVolumeTier v-for="(item, idx) in editableTiers" :key="idx" :currency="currency" :value="item" :index="idx" @update-tier="updateTier" @remove="removeTier"/>
                    </tbody>
                </template>
            </v-simple-table>

            <p v-if="editableTiers.length === 0" class="mt-6 text-center">
                Press the + button to add a new tier.
            </p>

            <template v-if="Array.isArray(errors) && errors.length > 0">
                <p class="red--text" v-for="(error, idx) in errors" :key="idx" v-text="error.message"></p>
            </template>
        </v-card-text>
    </v-card>
</template>

<style scoped>

</style>

<script>
import EditableProductPriceVolumeTier from '@/components/EditableProductPriceVolumeTier.vue';

export default {
    components: {
        EditableProductPriceVolumeTier,
    },
    props: ['currency', 'value', 'errors'], // value must be a tiers array or null
    data: () => ({
        editMode: true,
        editableTiers: null, // a tiers array
        submitFormTimestamp: null,
    }),
    computed: {
    },
    watch: {
        editMode(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.initView();
            }
        },
    },
    methods: {
        initView() {
            if (Array.isArray(this.value)) {
                this.editableTiers = this.value;
            } else {
                this.editableTiers = [];
            }
        },
        addTier() {
            this.editableTiers.push({});
        },
        removeTier(idx) {
            console.log(`remove tier ${idx}`);
            this.editableTiers.splice(idx, 1);
            this.$emit('input', this.editableTiers);
        },
        updateTier(idx, item) {
            console.log(`2. update idx ${idx} item ${JSON.stringify(item)}`);
            this.$set(this.editableTiers, idx, item);
            this.$emit('input', this.editableTiers);
        },
        // submit() {
        //     if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
        //         return;
        //     }
        //     this.submitFormTimestamp = Date.now();
        //     const item = this.editableTiers;
        //     this.$emit('input', item);
        //     // this.cancel();
        // },
        // cancel() {
        //     this.editMode = false;
        //     this.editableUnitPrice = null;
        //     this.editableTiers = null;
        // },
    },
    mounted() {
        this.initView();
    },
};
</script>
