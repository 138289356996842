<template>
    <v-card flat tile>
        <v-card-text>
            <p>Customer will be charged this amount at checkout.</p>
            <v-text-field v-model="editableFlatPrice" label="Flat price" hint="Currency amount" dense="dense" ref="editableFlatPriceInput" class="my-6" @change="submit"></v-text-field>

            <template v-if="Array.isArray(errors) && errors.length > 0">
                <p class="red--text" v-for="(error, idx) in errors" :key="idx" v-text="error.message"></p>
            </template>
        </v-card-text>
    </v-card>
</template>

<style scoped>

</style>

<script>
import { isValidUnsignedDecimalString, fromCurrencyAmountCSU, fromCurrencyAmountCDS } from '@libertyio/currency-util-js';

export default {
    props: ['currency', 'value', 'errors'], // value must be a tiers array or null
    data: () => ({
        editMode: true,
        editableFlatPrice: null,
        editableFlatPriceAny: null,
        editableTiers: null, // a tiers array
        submitFormTimestamp: null,
    }),
    computed: {
    },
    watch: {
        editMode(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.initView();
            }
        },
    },
    methods: {
        initView() {
            if (Array.isArray(this.value)) {
                this.editableTiers = this.value;
            } else {
                this.editableTiers = [];
            }
            if (this.editableTiers.length > 1) {
                this.editableTiers.splice(1); // flat price requires a single tier, so delete all tiers after first one
            }
            if (this.editableTiers.length > 0) {
                const tier = this.editableTiers[0];
                this.editableFlatPrice = Number.isInteger(tier.flat_price_csu) ? fromCurrencyAmountCSU(this.currency, tier.flat_price_csu).toString() : '';
            } else {
                this.editableFlatPrice = null;
            }
            this.$nextTick(() => {
                setTimeout(() => { this.$activateInput('editableFlatPriceInput'); }, 1);
            });
        },
        submit() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            let flatPriceCSU = null;
            if (this.editableFlatPrice?.length > 0 && isValidUnsignedDecimalString(this.editableFlatPrice)) {
                flatPriceCSU = fromCurrencyAmountCDS(this.currency, this.editableFlatPrice).toCSU();
            }
            this.editableTiers = [
                {
                    flat_price_csu: flatPriceCSU,
                    max: 1,
                },
            ];
            const item = this.editableTiers;
            this.$emit('input', item);
            // this.cancel();
        },
        // cancel() {
        //     this.editMode = false;
        //     this.editableFlatPrice = null;
        //     this.editableTiers = null;
        // },
    },
    mounted() {
        this.initView();
    },
};
</script>
