<template>
    <TextLink :href="href" :target="target"><slot>{{ product.name }}</slot></TextLink>
</template>
<script>
import TextLink from '@/components/TextLink.vue';

export default {
    components: {
        TextLink,
    },
    props: {
        organization: {
            type: Object, // should have setting.customer_website_url
        },
        product: {
            type: Object, // should have id, name, ref, alias
        },
        by: {
            type: String, // link by 'id', 'alias', or 'ref'
        },
        target: {
            type: String,
        },
    },
    computed: {
        href() {
            const baseurl = this.organization.setting?.customer_website_url;
            if (!baseurl) {
                return '#customer-website-url-not-found';
            }
            const separator = baseurl.endsWith('/') ? '' : '/';
            let path;
            const type = this.product?.type; // TODO: what happens if it's unknown? send them to catalog instead? or just assume merchandise?
            if (type === 'saas') {
                path = 'signup';
            } else {
                path = 'item';
            }
            const query = new URLSearchParams();
            if (this.by === 'alias' && this.product?.alias) {
                query.set('product_alias', this.product.alias);
            } else if (this.by === 'ref' && this.product?.ref) {
                query.set('product_ref', this.product.ref);
            // } else if (this.by === 'id' && this.product?.id) {
            //     query.set('id', this.product.id);
            } else if (this.product?.id) {
                query.set('product_id', this.product.id);
            } else {
                path = 'catalog';
            }
            const querystring = query.toString();
            return `${baseurl}${separator}${path}?${querystring}`;
        },
    },
};
</script>
