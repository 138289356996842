<template>
    <v-card flat tile>
        <v-card-text>
            <v-checkbox v-model="confirm" label="Customer will not be charged for this item at checkout" dense></v-checkbox>
        </v-card-text>
    </v-card>
</template>

<style scoped>

</style>

<script>
export default {
    props: ['currency', 'value', 'errors'], // value must be a tiers array or null
    data: () => ({
        editMode: true,
        confirm: false,
    }),
    computed: {
    },
    watch: {
        editMode(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.initView();
            }
        },
        confirm(newValue) {
            if (newValue) {
                this.$emit('input', []);
            } else {
                this.$emit('input', null);
            }
        },
    },
    mounted() {
        if (this.value === null) {
            this.confirm = false;
        }
        if (Array.isArray(this.value)) {
            this.confirm = true;
        }
    },
};
</script>
