<template>
    <v-card flat tile>
        <template v-if="editableValue">
        <v-card-text>
            <!-- <p class="text-overline mb-0 mt-0">Service</p> -->
            <p class="text-overline mb-0 mt-8">SaaS Info URL</p>
            <p class="mb-0 pb-0 mt-0 text-caption">
                A public website where potential customers can learn more about the service without signing in.
            </p>
            <p class="mb-0 pb-0 mt-0">
                <EditableText :value="editableValue.saas_info_url" @input="saveServiceSaaSInfoURL" dense>
                    <template #view--after-mode-buttons>
                        <TextLink :href="editableValue.saas_info_url" target="_blank"></TextLink>
                    </template>
                </EditableText>
            </p>

            <!-- TODO: this assumes the sso provider is loginfront, using sso app tags; if we support other sso/idp we'll need a diffrent configuration for them -->
            <template v-if="isAuthenticationModeSSO">
                <p class="text-overline mb-0 mt-8">SaaS Single Sign-On</p>
                <p class="mb-0 pb-0 mt-0 text-caption">
                    Enter the application tag to be used when redirecting users to this service via the SSO.
                    <!-- Application tags are defined in LoginFront. -->
                </p>
                <p class="mb-0 pb-0 mt-0">
                    <EditableText :value="editableValue.saas_sso_app_tag" @input="saveServiceSaaSSSOAppTag" dense>
                        <template #view--after-mode-buttons>
                            <TextLink :href="editableValue.saas_sso_app_tag" target="_blank"></TextLink>
                        </template>
                    </EditableText>
                </p>
            </template>

            <template v-if="!isAuthenticationModeSSO">
                <p class="text-overline mb-0 mt-8">SaaS Entry URL</p>
                <p class="mb-0 pb-0 mt-0 text-caption">
                    The entry point to the service for your customers. If you have a dashboard URL that will either show content or redirect user to sign in, that would be ideal. Otherwise, use the login URL for your service.
                </p>
                <p class="mb-0 pb-0 mt-0">
                    <EditableText :value="editableValue.saas_entry_url" @input="saveServiceSaaSEntryURL" dense>
                        <template #view--after-mode-buttons>
                            <TextLink :href="editableValue.saas_entry_url" target="_blank"></TextLink>
                        </template>
                    </EditableText>
                </p>
            </template>

            <!-- <v-expansion-panels class="mt-8" v-if="isAuthenticationModeSSO">
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span>
                            <font-awesome-icon :icon="['fas', 'cog']" class="grey--text text--darken-2 mr-2"></font-awesome-icon>
                            Single sign-on
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content style="font-size: 0.85em;">
                        <p class="mb-0 pb-0 mt-2">
                            <EditableText :value="loginfrontRealm" label="LoginFront Realm" @input="saveLoginFrontRealm" dense/>
                        </p>
                        <p class="mb-0 pb-0 mt-2">
                            <EditableText :value="loginfrontClientTokenDisplay" label="LoginFront Client Token" @input="saveLoginFrontClientToken" dense/>
                        </p>
                        <p class="mb-0 pb-0 mt-2">
                            <EditableText :value="loginfrontAccessTokenDisplay" label="LoginFront Access Token" @input="saveLoginFrontAccessToken" dense/>
                        </p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels> -->

            <p class="text-overline mb-0 mt-8">Limit 1 per profile?</p>
            <p class="mb-0 pb-0 mt-0 text-caption">
                If this is a <strong>single-user free tier</strong> with resource limits, check this box to limit one per profile. This means if the same customer (identified by email address) creates additional accounts, they cannot add this service to other accounts if they already have it in one of their accounts.
            </p>
            <p class="mb-0 pb-0 mt-0">
                <v-checkbox :input-value="editableValue.limit_one_per_profile" @change="saveLimitOnePerProfile" dense>
                    <template #label>
                        Limit one per profile
                    </template>
                </v-checkbox>
            </p>

        </v-card-text>
                <!-- NOTE ABOUT ONE PER PROFILE: this does not limit a user's ability to be associated to multiple accounts that have a paid tier of this service, because it's technically a different service id -->
                <!-- For example: A free tier is offered with 1 unit of some free resource, such as 1 GB of storage. If this box is not checked, a customer can use a single profile (email address) and create 10 free accounts, each with 1 unit free. But if this box is checked, a customer would have to create multiple profiles (10 different email addresses) to create 10 free accounts, so it's less convenient. -->
                <!-- And in the future we may also apply additional detection such as by location, usage patterns, or other data to detect and stop abuse of free accounts. -->
        <v-card-text>
            <p class="text-overline mb-0 mt-0">
                Resource
                <v-btn icon color="green darken-2" @click="addResourceDialog = true">
                    <font-awesome-icon :icon="['fas', 'plus']" fixed-width></font-awesome-icon>
                </v-btn>
            </p>
            <p class="mb-0 pb-0 mt-0 text-caption">
                <!-- TODO: move this to documentation, put a link to the docs here under an 'i' icon -->
                Define resources to track disk space, compute hours, active users, licenses, or anything else you need to monitor or bill for usage. A resource can be 'licensed' (billed in advance, at the start of the billing period, for the requested quantity) or 'metered' (billed at the end of the billing period for the amount actually used). Each resource has its own pricing. You can make a resource free to track usage without billing customers.
            </p>
            <v-list v-if="Array.isArray(editableValue.resource_list)">
                <v-list-item v-for="item in editableValue.resource_list" :key="item.id">
                    <v-list-item-content>
                        <!-- <EditableProductPrice :value="item" :product="product" @input="saveProductPrice" @delete="deleteProductPrice"/> -->
                        <!-- a resource is also a product -->
                        <v-list-item-title>
                            {{ item.name }}
                            <span class="grey--text text--darken-2 ml-2" style="font-size: 0.75em;" v-if="item.price">
                                <font-awesome-icon :icon="['fas', 'tag']" style="font-size: 0.75em;"></font-awesome-icon>
                                {{ item.price.alias }}
                            </span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <router-link :to="{ name: 'organization-edit-product', params: { organizationId: $route.params.organizationId }, query: { id: item.id, t: Date.now() } }">Edit resource</router-link>
                            |
                            <template v-if="!item.price">
                                <span class="amber--text text--darken-2 mx-2" @click="editResourcePrice(item.id)" style="cursor: pointer;">
                                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']"></font-awesome-icon>
                                </span>
                            </template>
                            <TextButton underline class="green--text text--darken-2" @click="editResourcePrice(item.id)">Select price</TextButton>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-menu offset-y left open-on-click open-on-hover close-delay="100">
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on">
                                    <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                                </v-btn>
                            </template>
                            <v-list class="ma-0 pa-0">
                                <v-list-item-group>
                                    <v-list-item @click="unlinkResource(item.id)">
                                        <v-list-item-icon>
                                            <font-awesome-icon :icon="['fas', 'trash']" class="red--text text--darken-2" fixed-width></font-awesome-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Remove
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-menu>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card-text>
        <v-dialog v-model="addResourceDialog" max-width="600">
            <v-card class="pb-2 pt-0">
                <v-toolbar dense flat color="green darken-2" dark>
                    <v-toolbar-title>Add resource</v-toolbar-title>
                </v-toolbar>
                <v-tabs v-model="createResourceTab">
                    <v-tab>Link existing resource</v-tab>
                    <v-tab>Create new resource</v-tab>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-title>Link existing resource</v-card-title>
                            <v-card-text>
                                <p>Select a resource to add to the service.</p>
                            <v-form @submit.prevent="linkResource" @keyup.enter.native.prevent="linkResource">
                                <v-select v-model="existingResourceId" label="Resource name" :items="resourceChoiceList"  outlined dense></v-select>
                            </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn elevation="4" class="green darken-2 white--text" @click="linkResource" :disabled="!existingResourceId">
                                    <span>Add</span>
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <p>Enter a name for the new resource. You will be able to edit it later.</p>
                            <!-- <v-card-title>Create new resource</v-card-title> -->
                            <v-form @submit.prevent="createResource" @keyup.enter.native.prevent="createResource">
                                <v-text-field v-model="newResourceName" label="Resource name" hint="The resource name. This will be displayed to customers." ref="newResourceNameInput" outlined dense></v-text-field>
                            </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn elevation="4" class="green darken-2 white--text" @click="createResource" :disabled="!isNewResourceFormComplete">
                                    <span>Create</span>
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editResourcePriceDialog" max-width="600">
            <v-card class="pb-2 pt-0">
                <v-toolbar dense flat color="green darken-2" dark>
                    <v-toolbar-title>Select resource price</v-toolbar-title>
                </v-toolbar>
                <template v-if="Array.isArray(selectedResourcePriceList) && selectedResourcePriceList.length > 0">
                    <v-card-text>
                        <p class="mt-8 mb-0">Select a price to assign to this resource when setting up the subscription.</p>
                    <v-list>
                        <v-list-item @click="selectResourcePrice(selectedResourceProductId, price.id)" v-for="price in selectedResourcePriceList" :key="price.id">
                            <v-list-item-content>
                                <v-list-item-title>{{ price.alias }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    </v-card-text>
                </template>
                <template v-else-if="Array.isArray(selectedResourcePriceList) && selectedResourcePriceList.length === 0">
                    <v-card-text>
                        <p class="mt-8 mb-0">No prices defined for this resource.</p>
                        <p><router-link :to="{ name: 'organization-edit-product', params: { organizationId: $route.params.organizationId }, query: { id: selectedResourceProductId, t: Date.now() } }">Edit resource</router-link></p>
                    </v-card-text>
                </template>
                <template v-else>
                    <v-card-text>
                        <p class="mt-8 mb-0">Loading prices...</p>
                    </v-card-text>
                </template>
            </v-card>
        </v-dialog>
        <v-card-text>
            <p class="text-overline mb-0 mt-0">
                Asset
                <v-btn icon color="green darken-2" @click="addAssetDialog = true">
                    <font-awesome-icon :icon="['fas', 'plus']" fixed-width></font-awesome-icon>
                </v-btn>
            </p>
            <p class="mb-0 pb-0 mt-0 text-caption">
                <!-- TODO: move this to documentation, put a link to the docs here under an 'i' icon -->
                Define assets to track virtual machines, customized documents, or any other user creation that you need to monitor or bill for usage. An asset is always 'licensed' (billed in advance, at the start of the billing period, for each asset created and there may be a custom price for each one) but it can also be linked to resources which are either 'licensed' or 'metered' (billed at the end of the billing period for the amount actually used). Each asset has its own pricing. You can make an free to track usage without billing customers, or to only bill for associated resources. For example, a cloud computing service allows users to create virtual machines with custom specifications. Each created VM is an asset. There is no charge for the VM itself, but there is an associated 'compute hours' resource representing how many hours the VM was powered on and users are billed for that, and that associated resource has a custom price based on the asset's specifications (how many vCPU, how much memory, how much storage, how much outbound transfer, whether automatic backups are enabled, etc.)
            </p>
            <v-list v-if="Array.isArray(editableValue.asset_list)">
                <v-list-item v-for="item in editableValue.asset_list" :key="item.id">
                    <v-list-item-content>
                        <!-- <EditableProductPrice :value="item" :product="product" @input="saveProductPrice" @delete="deleteProductPrice"/> -->
                        <!-- a asset is also a product -->
                        <!-- TODO: list the prices defined for the asset product, and let the administrator choose which ones are available when that asset is associated with this saas product (there could be free, standard, discounted, etc. prices there, or slightly different prices for different saas, so we can select a specific price id here, and keep it in the link serviceproduct assetproduct table if we add a content field there) -->
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle><router-link :to="{ name: 'organization-edit-product', params: { organizationId: $route.params.organizationId }, query: { id: item.id, t: Date.now() } }">Edit asset</router-link></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-menu offset-y left open-on-click open-on-hover close-delay="100">
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on">
                                    <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                                </v-btn>
                            </template>
                            <v-list class="ma-0 pa-0">
                                <v-list-item-group>
                                    <v-list-item @click="unlinkAsset(item.id)">
                                        <v-list-item-icon>
                                            <font-awesome-icon :icon="['fas', 'trash']" class="red--text text--darken-2" fixed-width></font-awesome-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Remove
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-menu>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card-text>
        <v-dialog v-model="addAssetDialog" max-width="600">
            <v-card class="pb-2 pt-0">
                <v-toolbar dense flat color="green darken-2" dark>
                    <v-toolbar-title>Add asset</v-toolbar-title>
                </v-toolbar>
                <v-tabs v-model="createAssetTab">
                    <v-tab>Link existing asset</v-tab>
                    <v-tab>Create new asset</v-tab>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-title>Link existing asset</v-card-title>
                            <v-card-text>
                                <p>Select an asset to add to the service.</p>
                            <v-form @submit.prevent="linkAsset" @keyup.enter.native.prevent="linkAsset">
                                <v-select v-model="existingAssetId" label="Asset name" :items="assetChoiceList" outlined dense></v-select>
                            </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn elevation="4" class="green darken-2 white--text" @click="linkAsset" :disabled="!existingAssetId">
                                    <span>Add</span>
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <p>Enter a name for the new asset. You will be able to edit it later.</p>
                            <v-form @submit.prevent="createAsset" @keyup.enter.native.prevent="createAsset">
                                <v-text-field v-model="newAssetName" label="Asset name" hint="The asset name. This will be displayed to customers." ref="newAssetNameInput" outlined dense></v-text-field>
                            </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn elevation="4" class="green darken-2 white--text" @click="createAsset" :disabled="!isNewAssetFormComplete">
                                    <span>Create</span>
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-dialog>
        </template>
    </v-card>
</template>

<style scoped>

</style>

<script>
import EditableText from '@/components/EditableText.vue';
import TextButton from '@/components/TextButton.vue';
import TextLink from '@/components/TextLink.vue';
import { AUTHENTICATION_MODE_SSO } from '@/sdk/constants';

export default {
    // value is the type-specific area in the 'content' section of the product record; when we emit input it will be a modified value
    // props: {
    //     productId: {
    //         type: String,
    //         required: true,
    //     },
    //     value: {
    //         type: Object, // we need to also allow undefined
    //         required: true,
    //     },
    // },
    props: ['productId', 'value'],
    components: {
        EditableText,
        TextButton,
        TextLink,
    },
    data: () => ({
        editableValue: null,
        resourceProductList: null, // list of all resources, used for the 'link existing resource' option
        assetProductList: null,
        addResourceDialog: false,
        addAssetDialog: false,
        // resourceRadioGroup: 'create', // ['create', 'link']
        createResourceTab: 0,
        createAssetTab: 0,
        newResourceName: null,
        newAssetName: null,
        submitTimestamp: null,
        resourceChoiceList: [],
        existingResourceId: null,
        assetChoiceList: [],
        existingAssetId: null,
        // select resource price
        selectedResourceProductId: null, // resource being edited to select price
        selectedResourcePriceList: null, // list of product price items for the selected resource
        editResourcePriceDialog: false,
        // authentication mode
        authenticationMode: null,
        error: false,
    }),
    computed: {
        isNewResourceFormComplete() {
            return typeof this.newResourceName === 'string' && this.newResourceName.trim().length > 0;
        },
        isNewAssetFormComplete() {
            return typeof this.newAssetName === 'string' && this.newAssetName.trim().length > 0;
        },
        isAuthenticationModeSSO() {
            return this.authenticationMode === AUTHENTICATION_MODE_SSO;
        },
    },
    watch: {
        addResourceDialog(newValue) {
            if (newValue) {
                this.createResourceTab = 0;
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('newResourceNameInput'); }, 1);
                });
            }
        },
        createResourceTab(newValue) {
            if (newValue === 0) {
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('newResourceNameInput'); }, 1);
                });
            }
        },
        value(newValue) {
            this.editableValue = newValue ?? {};
        },
        // editMode(newValue, oldValue) {
        //     if (newValue && !oldValue) {
        //         this.initView();
        //     }
        // },
    },
    methods: {
        async saveServiceSaaSInfoURL(value) {
            try {
                const w = new URL(value);
                const saasInfo = this.editableValue ?? {};
                // content.saas_info_url = w.toString();
                this.$set(saasInfo, 'saas_info_url', w.toString());
                this.$emit('input', saasInfo);
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Invalid Info URL', message: 'That is not a valid URL' });
            }
        },
        async saveServiceSaaSEntryURL(value) {
            try {
                const w = new URL(value);
                const saasInfo = this.editableValue ?? {};
                // content.saas_entry_url = w.toString();
                this.$set(saasInfo, 'saas_entry_url', w.toString());
                this.$emit('input', saasInfo);
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Invalid Entry URL', message: 'That is not a valid URL' });
            }
        },
        async saveServiceSaaSSSOAppTag(value) {
            try {
                const saasInfo = this.editableValue ?? {};
                this.$set(saasInfo, 'saas_sso_app_tag', value);
                this.$emit('input', saasInfo);
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Invalid SSO app tag', message: 'Failed to save the SSO app tag' }); // That is not a valid app tag
            }
        },
        async saveLimitOnePerProfile(value) {
            const saasInfo = this.editableValue ?? {};
            this.$set(saasInfo, 'limit_one_per_profile', value);
            this.$emit('input', saasInfo);
        },
        async createResource() {
            // TODO: create or link a resource product record, associate it to this product
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            try {
                this.$store.commit('loading', { createResource: true });
                const response = await this.$client.organization(this.$route.params.organizationId).product.create({
                    name: this.newResourceName,
                    type: 'resource',
                });
                console.log(`createResource: response ${JSON.stringify(response)}`);
                if (response?.isCreated && response.item) {
                    const editedItem = { ...response.item, is_active: false, is_draft_pending: true };
                    this.resourceProductList ??= [];
                    this.resourceProductList.push(editedItem);

                    const saasInfo = this.editableValue ?? {};
                    const resourceList = saasInfo.resource_list ?? [];

                    resourceList.push({ id: response.item.id, name: response.item.name });

                    this.$set(saasInfo, 'resource_list', resourceList);
                    this.$emit('input', saasInfo);
                    this.addResourceDialog = false;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create resource' });
                }
            } catch (err) {
                console.error('failed to create new resource', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create resource' });
            } finally {
                this.$store.commit('loading', { createResource: false });
            }
        },
        async createAsset() {
            // TODO: create or link an asset product record, associate it to this product
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            try {
                this.$store.commit('loading', { createAsset: true });
                const response = await this.$client.organization(this.$route.params.organizationId).product.create({
                    name: this.newAssetName,
                    type: 'asset',
                });
                console.log(`createAsset: response ${JSON.stringify(response)}`);
                if (response?.isCreated && response.item) {
                    const editedItem = { ...response.item, is_active: false, is_draft_pending: true };
                    this.assetProductList ??= [];
                    this.assetProductList.push(editedItem);
                    const saasInfo = this.editableValue ?? {};
                    const assetList = saasInfo.asset_list ?? [];

                    assetList.push({ id: response.item.id, name: response.item.name });

                    this.$set(saasInfo, 'asset_list', assetList);
                    this.$emit('input', saasInfo);
                    this.addAssetDialog = false;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create asset' });
                }
            } catch (err) {
                console.error('failed to create new asset', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create asset' });
            } finally {
                this.$store.commit('loading', { createAsset: false });
            }
        },
        async loadResourceProductList() {
            try {
                this.$store.commit('loading', { loadResourceProductList: true });
                const response = await this.$client.organization(this.$route.params.organizationId).product.search({
                    type: 'resource',
                });
                console.log(`loadResourceProductList: response ${JSON.stringify(response)}`);
                if (Array.isArray(response.list)) {
                    this.resourceProductList = response.list;
                    this.resourceChoiceList = response.list.map((resourceProduct) => ({ value: resourceProduct.id, text: resourceProduct.name }));
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load resource product list' });
                }
            } catch (err) {
                console.error('failed to load resource product list', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load resource product list' });
            } finally {
                this.$store.commit('loading', { loadResourceProductList: false });
            }
        },
        async loadAssetProductList() {
            try {
                this.$store.commit('loading', { loadAssetProductList: true });
                const response = await this.$client.organization(this.$route.params.organizationId).product.search({
                    type: 'asset',
                });
                console.log(`loadAssetProductList: response ${JSON.stringify(response)}`);
                if (Array.isArray(response.list)) {
                    this.assetProductList = response.list;
                    this.assetChoiceList = response.list.map((assetProduct) => ({ value: assetProduct.id, text: assetProduct.name }));
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load asset product list' });
                }
            } catch (err) {
                console.error('failed to load asset product list', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load asset product list' });
            } finally {
                this.$store.commit('loading', { loadAssetProductList: false });
            }
        },
        // NOTE: instead of loading linked resources or assets from link__resource_product__service_product, we get it from the draft product's saas-specific content because we want the list of resources linked IN THIS DRAFT, so it means they might not be linked yet in the actual link table; the links will be created/removed by the server when we publish the product; in addition to the resource product id we also store the resource product name so that we can immediately display it when we reload this page, instead of waiting for another query to fetch the resource names
        async linkResource() {
            try {
                this.$store.commit('loading', { linkResource: true });
                console.log(`linkResource: existingResourceId ${JSON.stringify(this.existingResourceId)}`);
                if (this.existingResourceId) {
                    const resourceProduct = this.resourceProductList.find((item) => item.id === this.existingResourceId);
                    if (!resourceProduct) {
                        this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to link resource' });
                        return;
                    }

                    const saasInfo = this.editableValue ?? {};
                    const resourceList = saasInfo.resource_list ?? [];

                    // if it's not in the list, add it; if it's already in the list, update the label
                    const linkedResourceIdx = resourceList.findIndex((item) => item.id === resourceProduct.id);
                    if (linkedResourceIdx < 0) {
                        resourceList.push({ id: resourceProduct.id, name: resourceProduct.name });
                    } else {
                        resourceList[linkedResourceIdx].name = resourceProduct.name;
                    }

                    this.$set(saasInfo, 'resource_list', resourceList);
                    this.$emit('input', saasInfo);

                    this.addResourceDialog = false;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to link resource' });
                }
            } catch (err) {
                console.error('failed to link existing resource', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to link resource' });
            } finally {
                this.$store.commit('loading', { linkResource: false });
            }
        },
        async unlinkResource(id) {
            try {
                this.$store.commit('loading', { unlinkResource: true });
                console.log(`unlinkResource: id ${JSON.stringify(id)}`);
                const saasInfo = this.editableValue ?? {};
                const resourceList = saasInfo.resource_list ?? [];
                const itemIdx = resourceList.findIndex((item) => item.id === id);
                if (itemIdx > -1) {
                    resourceList.splice(itemIdx, 1);
                }
                this.$set(saasInfo, 'resource_list', resourceList);
                this.$emit('input', saasInfo);
            } catch (err) {
                console.error('failed to unlink existing resource', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to link resource' });
            } finally {
                this.$store.commit('loading', { unlinkResource: false });
            }
        },
        async linkAsset() {
            try {
                this.$store.commit('loading', { linkAsset: true });
                console.log(`linkAsset: existingAssetId ${JSON.stringify(this.existingAssetId)}`);
                if (this.existingAssetId) {
                    const assetProduct = this.assetProductList.find((item) => item.id === this.existingAssetId);
                    if (!assetProduct) {
                        this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to link asset' });
                        return;
                    }

                    const saasInfo = this.editableValue ?? {};
                    const assetList = saasInfo.asset_list ?? [];

                    // if it's not in the list, add it; if it's already in the list, update the label
                    const linkedAssetIdx = assetList.findIndex((item) => item.id === assetProduct.id);
                    if (linkedAssetIdx < 0) {
                        assetList.push({ id: assetProduct.id, name: assetProduct.name });
                    } else {
                        assetList[linkedAssetIdx].name = assetProduct.name;
                    }

                    this.$set(saasInfo, 'asset_list', assetList);
                    this.$emit('input', saasInfo);

                    this.addAssetDialog = false;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to link asset' });
                }
            } catch (err) {
                console.error('failed to link existing asset', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to link asset' });
            } finally {
                this.$store.commit('loading', { linkAsset: false });
            }
        },
        async unlinkAsset(id) {
            try {
                this.$store.commit('loading', { unlinkAsset: true });
                console.log(`unlinkAsset: id ${JSON.stringify(id)}`);
                const saasInfo = this.editableValue ?? {};
                const assetList = saasInfo.asset_list ?? [];
                const itemIdx = assetList.findIndex((item) => item.id === id);
                if (itemIdx > -1) {
                    assetList.splice(itemIdx, 1);
                }
                this.$set(saasInfo, 'asset_list', assetList);
                this.$emit('input', saasInfo);
            } catch (err) {
                console.error('failed to unlink existing asset', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to link asset' });
            } finally {
                this.$store.commit('loading', { unlinkAsset: false });
            }
        },
        async editResourcePrice(resourceProductId) {
            this.selectedResourceProductId = resourceProductId;
            this.selectedResourcePriceList = null;
            this.editResourcePriceDialog = true;
            this.selectedResourcePriceList = await this.loadProductPriceList(resourceProductId);
        },
        async loadProductPriceList(productId) {
            try {
                this.$store.commit('loading', { loadProductPriceList: true });
                const response = await this.$client.organization(this.$route.params.organizationId).productPrice.search({
                    product_id: productId,
                    published: 'true',
                });
                console.log(`loadProductPriceList: response ${JSON.stringify(response)}`);
                if (Array.isArray(response.list)) {
                    return response.list;
                }
                return null;
            } catch (err) {
                console.error('failed to load price list', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load price list' });
                return null;
            } finally {
                this.$store.commit('loading', { loadProductPriceList: false });
            }
        },
        async selectResourcePrice(resourceProductId, priceId) {
            try {
                console.log(`selectResourcePrice: resource id ${resourceProductId} price id ${priceId}`);
                const saasInfo = this.editableValue ?? {};
                const resourceList = saasInfo.resource_list ?? [];

                // resource must already be in list
                const selectedResourceIdx = resourceList.findIndex((item) => item.id === resourceProductId);
                if (selectedResourceIdx < 0) {
                    console.error(`selectResourcePrice: resource product id ${resourceProductId} not found in resource list`);
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to select resource price' });
                    return;
                }

                // price must be in loaded price list
                const selectedPriceIdx = this.selectedResourcePriceList.findIndex((item) => item.id === priceId);
                if (selectedPriceIdx < 0) {
                    console.error(`selectResourcePrice: price id ${priceId} not found in price list`);
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to select resource price' });
                    return;
                }

                const selectedResource = resourceList[selectedResourceIdx];
                const selectedPrice = this.selectedResourcePriceList[selectedPriceIdx];

                selectedResource.price = { id: selectedPrice.id, alias: selectedPrice.alias };

                this.$set(resourceList, selectedResourceIdx, selectedResource);
                this.$set(saasInfo, 'resource_list', resourceList);
                this.$emit('input', saasInfo);
            } catch (err) {
                console.error('selectResourcePrice: failed', err);
            } finally {
                this.editResourcePriceDialog = false;
            }
        },
        // See also organization/EditAuthenticationMode.vue
        async loadAuthenticationMode() {
            this.authenticationMode = await this.loadSetting('authentication_mode');
        },
        // See also organization/EditAuthenticationMode.vue
        async loadSetting(name) {
            try {
                this.error = false;
                this.$store.commit('loading', { loadSetting: true });
                const response = await this.$client.organization(this.$route.params.organizationId).setting.get({ name });
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    return response.content;
                }
                return null;
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
                return null;
            } finally {
                this.$store.commit('loading', { loadSetting: false });
            }
        },
    },
    mounted() {
        this.editableValue = this.value ?? {};
        // this.loadLinkedResourceProductList(); // removed because we're editing a draft, and this would load published links;  the publish API will create and remove these links according to what's in the draft being published
        this.loadResourceProductList();
        this.loadAssetProductList();
        this.loadAuthenticationMode();
    },
};
</script>
