<template>
    <v-card flat tile>
        <template v-if="editableValue">
        <v-card-text>
            <!-- <p class="text-overline mb-0 mt-0">Service</p> -->
            <p class="text-overline mb-0 mt-8">Download URL</p>
            <p class="mb-0 pb-0 mt-0 text-caption">
                The URL where a customer can download the product after purchase.
                <!-- TODO: an admin could put a link to a cygnia doc, for example... ideally, we would use the cygnia doc api to request the download with a watermark (if it's a PDF) and only allow this during first 24 hours after purchase for guest orders, or indefinitely for signed-in users -->
            </p>
            <p class="mb-0 pb-0 mt-0">
                <EditableText :value="editableValue.download_url" @input="saveDownloadURL" dense>
                    <template #view--after-mode-buttons>
                        <TextLink :href="editableValue.download_url" target="_blank"></TextLink>
                    </template>
                </EditableText>
            </p>

        </v-card-text>
        </template>
    </v-card>
</template>

<style scoped>

</style>

<script>
import EditableText from '@/components/EditableText.vue';
import TextLink from '@/components/TextLink.vue';

export default {
    // value is the type-specific area in the 'content' section of the product record; when we emit input it will be a modified value
    // props: {
    //     productId: {
    //         type: String,
    //         required: true,
    //     },
    //     value: {
    //         type: Object, // we need to also allow undefined
    //         required: true,
    //     },
    // },
    props: ['productId', 'value'],
    components: {
        EditableText,
        TextLink,
    },
    data: () => ({
        editableValue: null,
        error: false,
    }),
    computed: {
    },
    watch: {
        value(newValue) {
            this.editableValue = newValue ?? {};
        },
    },
    methods: {
        async saveDownloadURL(value) {
            try {
                const w = new URL(value);
                const downloadInfo = this.editableValue ?? {};
                this.$set(downloadInfo, 'download_url', w.toString());
                this.$emit('input', downloadInfo);
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Invalid Download URL', message: 'That is not a valid URL' });
            }
        },
    },
    mounted() {
        this.editableValue = this.value ?? {};
    },
};
</script>
