<template>
    <tr>
        <td>
            {{ index + 1 }}
        </td>
        <td>
            <v-text-field v-model="editableTierMax" dense="dense" ref="editableTierMaxInput" class="my-6" @change="update"></v-text-field>
        </td>
        <td>
            <v-text-field v-model="editableFlatPrice" dense="dense" ref="editableFlatPriceInput" class="my-6" @change="update"></v-text-field>
        </td>
        <td>
            <v-text-field v-model="editableUnitPrice" dense="dense" ref="editableUnitPriceInput" class="my-6" @change="update"></v-text-field>
        </td>
        <td>
            <v-btn icon color="red darken-2" @click="removeTier">
                <font-awesome-icon :icon="['fas', 'trash']" fixed-width></font-awesome-icon>
            </v-btn>
        </td>
    </tr>
</template>

<style scoped>

</style>

<script>
import { isValidUnsignedDecimalString, fromCurrencyAmountCSU, fromCurrencyAmountCDS } from '@libertyio/currency-util-js';

export default {
    props: ['currency', 'value', 'index'], // value is a tier object, index is the position in the tiers array used for update/remove events
    data: () => ({
        editMode: true,
        editableFlatPrice: null,
        editableUnitPrice: null,
        editableTierMax: null,
        updateFormTimestamp: null,
    }),
    computed: {
    },
    watch: {
        value(newValue, oldValue) {
            console.log(`package tier value changed, old value ${JSON.stringify(oldValue)} new value ${JSON.stringify(newValue)}`);
            this.initView();
        },
        editMode(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.initView();
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('editableTierMaxInput'); }, 1);
                });
            }
        },
    },
    methods: {
        initView() {
            console.log(`INIT VIEW index ${this.index} value ${JSON.stringify(this.value)}`);
            let tier;
            if (typeof this.value === 'object' && this.value !== null) {
                tier = this.value;
            } else {
                tier = {};
            }

            this.editableFlatPrice = Number.isInteger(tier.flat_price_csu) ? fromCurrencyAmountCSU(this.currency, tier.flat_price_csu).toString() : '';
            this.editableUnitPrice = isValidUnsignedDecimalString(tier.unit_price_cds) ? tier.unit_price_cds : '';
            this.editableTierMax = tier.max?.toString(10); // may be null
        },
        removeTier() {
            this.$emit('remove', this.index);
        },
        update() {
            if (Number.isInteger(this.updateFormTimestamp) && this.updateFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.updateFormTimestamp = Date.now();
            let max = null;
            if (this.editableTierMax?.length > 0) {
                max = Number.parseInt(this.editableTierMax, 10);
            }
            let flatPriceCSU = null;
            if (this.editableFlatPrice?.length > 0 && isValidUnsignedDecimalString(this.editableFlatPrice)) {
                flatPriceCSU = fromCurrencyAmountCDS(this.currency, this.editableFlatPrice).toCSU();
            }
            let unitPriceCDS = null;
            if (this.editableUnitPrice?.length > 0 && isValidUnsignedDecimalString(this.editableUnitPrice)) {
                unitPriceCDS = this.editableUnitPrice;
            }
            const item = {
                flat_price_csu: flatPriceCSU,
                unit_price_cds: unitPriceCDS,
                max,
            };
            console.log(`1. emitting update for index ${this.index} item ${JSON.stringify(item)}`);
            this.$emit('update:tier', this.index, item);
            // this.cancel();
        },
        // cancel() {
        //     this.editMode = false;
        //     this.editableUnitPrice = null;
        //     this.editableTiers = null;
        // },
    },
    mounted() {
        this.initView();
        this.$nextTick(() => {
            setTimeout(() => { this.$activateInput('editableTierMaxInput'); }, 1);
        });
    },
};
</script>
